import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "./light";
import logo from "../images/inverna_logotipo.svg";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6 no-underline text-primary-500
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("https://images.pexels.com/photos/96715/pexels-photo-96715.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-2xl sm:text-2xl md:text-4xl lg:text-4xl font-black leading-none text-center font-variant	`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose text-center mx-auto`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0 mx-auto`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-secondary-500 text-primary-700 hover:bg-primary-700 hover:text-white no-underline`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 no-underline`}
  }
`;

export default function Hero({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="#acerca">Acerca de</NavLink>
      <NavLink href="#servicios">Servicios</NavLink>
      <NavLink href="#politica">Política</NavLink>
    </NavLinks>,
  ],
  heading = (
    <>
      I N V E R N A D E R O S
      <wbr />
      <br />
      <span tw="text-primary-500 mr-3">D E L </span>
      <span tw="text-primary-500"> S U R</span>
    </>
  ),
  primaryActionUrl = "#acerca",
  primaryActionText = "Acerca de",
  secondaryActionUrl = "#servicios",
  secondaryActionText = "Servicios",
}) {
  return (
    <div>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
            <Content>
              <Heading>{heading}</Heading>
              <Paragraph>
                <img src={logo} alt="Invernaderos" />
              </Paragraph>
              <Actions>
                <a href={primaryActionUrl} className="action primaryAction">
                  {primaryActionText}
                </a>
                <a href={secondaryActionUrl} className="action secondaryAction">
                  {secondaryActionText}
                </a>
              </Actions>
            </Content>
          </LeftColumn>
          <RightColumn></RightColumn>
        </TwoColumn>
      </Container>
    </div>
  );
}
